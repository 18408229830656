import axios from 'axios'
import { ElMessageBox, Message } from  'element-plus'
import store from '@/store'
import { useStore } from "vuex"
import { getToken,getServerToken } from '@/utils/auth'

// create an axios instance
// console.log(process.env.VUE_APP_BASE_API)
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  //withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // window.console.log(store.getters.getstoken)
    if (store.getters.getstoken) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'bearer ' + getToken()
      
      
      // console.log('bearer ' + getToken());
    }
    console.log('request_apitoken外')
    if (getServerToken()!== null){
       console.log('request_apitoken')
      config.headers['Laytp-Admin-Token'] = 'bearer ' + getServerToken()
    }
    return config
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom status
   * Here is just an example
   * You can also judge the status by HTTP Status status
   */
  response => {
    const res = response.data
    // console.log(res);

    // if the custom code is not 1, it is judged as an error.
    if (res.code !== 0) {
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      // console.log( res.message)
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 10401 || res.code === 50012 || res.code === 50014) {
        // to re-login
        // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        //   confirmButtonText: 'Re-Login',
        //   cancelButtonText: 'Cancel',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // })
        ElMessageBox.alert(res.msg, '警告',)
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    // console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
