import { login, getInfo, signup } from '@/api/httpapi'
import {  getToken,setToken, removeToken,getServerToken } from '@/utils/auth'
import { createStore } from 'vuex'
import router, { resetRouter } from '@/router'
export default createStore({
  state: {
    token: getToken(),
    servertoken: getServerToken(),
    name: '',
    avatar: '',
    introduction: '',
    roles: []
  },
  getters:{
    getstoken: function (state){
      return state.token;
    },
    getServerToken: function (state){
      return state.servertoken;
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    }
  },
  actions: {
    login({ commit }, userInfo) {
      const { username, password } = userInfo
      return new Promise((resolve, reject) => {
        login({ username: username.trim(), password: password }).then(response => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          commit('SET_ROLES', data.role)
          setToken(data.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  
      // user login
    signup({ commit }, userInfo) {
        const { username, password } = userInfo
        return new Promise((resolve, reject) => {
          signup({ username: username.trim(), password: password }).then(response => {
            const { data } = response
            commit('SET_TOKEN', data.token)
            setToken(data.token)
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
  
    // get user info
    getInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(response => {
          window.console.log(data)
          const { data } = response
  
          if (!data) {
            reject('Verification failed, please Login again.')
          }
  
          //const { roles, name, avatar, introduction } = data
          const {  uname } = data
          const   roles  = data.role
          // roles must be a non-empty array
         if (!roles || roles.length <= 0) {
           reject('getInfo: roles must be a non-null array!')
         }
           
          commit('SET_ROLES', roles)
          commit('SET_NAME', uname)
         
          //commit('SET_AVATAR', avatar)
          //commit('SET_INTRODUCTION', introduction)
          resolve(data)
        }).catch(error => {
          window.console.log('wrror --- error')
          reject(error)
        })
      })
    },
  
    // user logout
    logout({ commit, state, dispatch }) {
      print(state);
      return new Promise((resolve, reject) => {
          
        //logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_NAME', '')
          removeToken()
          //resetRouter()
  
          // reset visited views and cached views
          // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
          dispatch('tagsView/delAllViews', null, { root: true })
  
          resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      })
    },
  
    // remove token
    resetToken({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resolve()
      })
    },
  
    // dynamically modify permissions
    async changeRoles({ commit, dispatch }, role) {
      const token = role + '-token'
  
      commit('SET_TOKEN', token)
      setToken(token)
  
      const { roles } = await dispatch('getInfo')
  
      resetRouter()
  
      // generate accessible routes map based on roles
      const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
      // dynamically add accessible routes
      router.addRoutes(accessRoutes)
  
      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, { root: true })
    }
  },
  modules: {
  }
})
