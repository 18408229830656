import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/choice',
    name: 'Choice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Choice.vue')
  },
  {
    path: '/card',
    name: 'Card',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Card.vue')
  },
  {
    path: '/cards',
    name: 'Cards',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Cards.vue')
  },
  {
    path: '/manual_paper',
    name: 'ManualPaper',
    component: () => import('../views/security_manual_paper.vue')
  },
  {
    path: '/paper',
    name: 'Paper',
    component: () => import('../views/security_paper.vue')
  },
  {
    path: '/manual_paper/show',
    name: 'ManualPaperShow',
    component: () => import('../views/security_admin_paper_show.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
