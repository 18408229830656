import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  const domain = process.env.VUE_APP_DOMAIN
  return Cookies.get(TokenKey,{ domain: domain })
}

export function setToken(token) {
  const domain = process.env.VUE_APP_DOMAIN
  return Cookies.set(TokenKey, token,{ domain: domain })
}

export function removeToken() {
  const domain = process.env.VUE_APP_DOMAIN
  return Cookies.remove(TokenKey,{ domain: domain })
}

const ServerTokenKey = 'teacher-admin-token'
export function getServerToken() {
  const domain = process.env.VUE_APP_DOMAIN
  return Cookies.get(ServerTokenKey,{ domain: domain })
}

export function setServerToken(token) {
  const domain = process.env.VUE_APP_DOMAIN
  return Cookies.set(ServerTokenKey, token,{ domain: domain })
}

export function removeServerToken() {
  const domain = process.env.VUE_APP_DOMAIN
  return Cookies.remove(ServerTokenKey,{ domain: domain })
}
